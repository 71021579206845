<template>
  <div>
    <b-container>
      <b-card class="my-4 pb-0">
      <b-row>
        <b-col md="9">
        <div>
          <div class="mt-4 header-horizontal-title position-relative">
            <h4 class="font-weight-bold position-relative text-primary py-1">
              {{ imagesInfo.title }}
            </h4>
          </div>
          <p class="font-size-18 text-justify mt-3">{{ imagesInfo.content }}</p>
        </div>

            <lightbox :items="[imagesInfo.featured_image , ...getAllImages]" :cells="2"></lightbox>

<!--          <SimpleGallery galleryID="my-test-gallery" :images="images" />-->

<!--        <div class="photo-container">-->
<!--          <div v-for="(image,key) in [ {url : imagesInfo.featured_image}, ...imagesInfo.gallery_media]" :key="key" :class="['gallery-container iq-border-radius-10 w-2',-->
<!--    [1,6,7].indexOf(key) > -1 ? 'h-3' : 'h-2']">-->
<!--            <div class="gallery-item">-->
<!--              <div class="image">-->
<!--                <img :src="image.url" :alt="key">-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
          <div class="d-flex justify-content-around align-items-center mt-5">
            <b-button
                v-if="imagesInfo.prev"
                @click="$router.push({ name: 'imageDetails', params: { imageId: imagesInfo.prev.id } })"
                variant="outline-primary" class="py-2 px-4 iq-border-radius-5">
              <span><i class="las la-angle-double-right"></i></span>
              <span>الخبر السابق</span>
            </b-button>
            <b-button
                v-if="imagesInfo.next"
                      @click="$router.push({ name: 'imageDetails', params: { imageId: imagesInfo.next.id } })"
                      variant="primary" class="py-2 px-4 iq-border-radius-5">
              <span>الخبر التالى</span>
              <span><i class="las la-angle-double-left"></i></span>
            </b-button>
          </div>
        </b-col>
      <b-col md="3" class="mt-4">
        <div>
          <h4 class="text-primary mb-4">{{ $t('endUser.images') }} الأكثر انتشارا</h4>
          <div class="border-1" v-for="(item ,key) in mostImagesData" :key="key">
            <router-link :to="{name:'imageDetails', params:{imageId: item.id}}">
              <img :src="item.featured_image" class="w-100">
              <h5 class="py-2">{{ item.title }}</h5>
              <p>{{item.content.length > 37 ? item.content.substring(0, 37) + '...' : item.content}}</p>
            </router-link>
          </div>
        </div>
      </b-col>
      </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import allImages from '@/FackApi/json/images.json'
import homeServices from '@/modules/primaryPages/home/services/home'
// import SimpleGallery from '@/modules/primaryPages/images/components/SimpleGallery'
export default {
  // components: { SimpleGallery },
  name: 'imageDetails',
  mounted () {
    core.index()
  },
  computed: {
    getAllImages () {
      return this.imagesInfo.gallery_media.map(data => data.url)
    }
  },
  data () {
    return {
      loadingProductInfo: false,
      selectedImage: null,
      imagesInfo: {},
      mostImagesData: []
    }
  },
  watch: {
    '$route' () {
      this.imageDetails()
    }
  },
  methods: {
    imageDetails () {
      this.loadingProductInfo = true
      homeServices.galleryDetails(this.$route.params.imageId).then(res => {
        this.imagesInfo = res.data
      }).finally(() => {
        this.loadingProductInfo = false
      })
    },
    mostImages () {
      this.loadingProductInfo = true
      homeServices.getMostImage().then(res => {
        this.mostImagesData = res.data.data
      }).finally(() => {
        this.loadingProductInfo = false
      })
    }
  },
  created () {
    this.imageDetails()
    this.mostImages()
    // eslint-disable-next-line eqeqeq
    var selectedImage = allImages.find(data => data.id == this.$route.params.id)
    if (selectedImage) {
      this.selectedImage = selectedImage
    }
  }
}
</script>

<style>
.lb-grid{
  display: flex;
}
.lb-item{
  height: 300px !important;
  position: relative;

}
.photo-container{
  display:grid;
  grid-template-columns: repeat(6,1fr);
  grid-auto-rows:100px 300px;
  grid-gap:20px;
  grid-auto-flow: dense;
}
.gallery-item{
  width:100%;
  height:100%;
  position:relative;
}
.gallery-item .image{
  width:100%;
  height:100%;
  border-radius: 15px;
  overflow:hidden;
}
.gallery-item .image img{
  width:100%;
  height:100%;
  object-fit: cover;
  object-position:50% 50%;
  cursor:pointer;
  transition:.5s ease-in-out;
}
.gallery-item:hover .image img{
  transform:scale(1.5);
}
.gallery-item .text{
  opacity:0;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  color:#fff;
  font-size:25px;
  pointer-events:none;
  z-index:4;
  transition: .3s ease-in-out;
  -webkit-backdrop-filter: blur(5px) saturate(1.8);
  backdrop-filter: blur(5px) saturate(1.8);
}
.gallery-item:hover .text{
  opacity:1;
  animation: move-down .3s linear;
  padding:1em;
  width:100%;
}
.w-1{
  grid-column: span 1;
}
.w-2{
  grid-column: span 2;
}
.w-3{
  grid-column: span 3;
}
.w-4{
  grid-column: span 4;
}
.w-5{
  grid-column: span 5;
}
.w-6{
  grid-column: span 6;
}
.h-1{
  grid-row: span 1;
}
.h-2{
  grid-row: span 2;
}
.h-3{
  grid-row: span 3;
}
.h-4{
  grid-row: span 4;
}
.h-5{
  grid-row: span 5;
}
.h-6{
  grid-row: span 6;
}
@media screen and (max-width:500px){
  .container{
    grid-template-columns: repeat(1,1fr);
  }
  .w-1,.w-2,.w-3,.w-4,.w-5,.w-6{
    grid-column:span 1;
  }
}
@keyframes move-down{
  0%{
    top:10%;
  }
  50%{
    top:35%;
  }
  100%{
    top:50%;
  }
}

</style>
